import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import ListItem from './ListItem'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Controls, PlayState } from 'react-gsap'
import { v4 as uuidv4 } from 'uuid'

class ListSection extends Component {
  state = {
    id: uuidv4(),
  }
  render() {
    const { heading, items, getStyle } = this.props
    const { styles } = this
    const { id } = this.state

    return (
      <div style={getStyle(styles().container)}>
        <Controller>
          <Scene triggerHook={'onEnter'} triggerElement={`#heading_${id}`} duration={1}>
            {progress =>
            {console.log(progress)
              return(
                <Tween duration={0.5} ease={'power2.out'} from={{opacity: 0, y: '100%'}} to={{opacity: 1, y: '0%'}} playState={progress ? PlayState.play : PlayState.stop}>
                  <div id={`heading_${id}`} style={getStyle(styles().heading)}>
                    {heading}
                  </div>
                </Tween>)
            }}
          </Scene>
        </Controller>
          <Controller>
            <Scene triggerHook={'onEnter'} triggerElement={`#underline_${id}`} duration={1}>
              {progress =>
              {console.log(progress)
                return(
                  <Tween duration={0.5} from={{scaleX: 0}} to={{scaleX: 1}} playState={progress ? PlayState.play : PlayState.stop}>
                    <div id={`underline_${id}`} style={getStyle(styles().divider)}/>
                  </Tween>)
              }}
            </Scene>
          </Controller>
        <div>
          {items.map((item, index) => {
            return <ListItem item={item} index={index}/>
          })}
        </div>
      </div>
    )
  }

  styles = () => {
    return {
      container: {
        backgroundColor: '#FFFFFF',
        paddingTop: 75,
      },
      heading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 0,
        paddingLeft: 75,
        paddingRight: 75,
        color: '#6AE1C6',
        fontWeight: 300,
        fontFamily: '"Gotham A", "Gotham B"',
        fontSize: 54,
        textTransform: 'uppercase',
        textAlign: 'center',
        sm: {
          fontSize: 45,
        },
        md: {
          paddingLeft: 35,
          paddingRight: 35,
        },
        xs: {
          fontSize: 38,
        },
        xxs: {
          fontSize: 32,
        },
      },
      divider: {
        height: 2,
        width: 250,
        backgroundColor: '#C9C9C7',
        marginTop: 50,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }
  }
}

export default withSizes(ListSection)
