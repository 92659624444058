import { SET_NAVBAR_BACKGROUND } from './actions'

const initialState = {
  showNavbarBackground: false,
}
const navigation = (state = initialState, action) => {
  switch(action.type) {
    case SET_NAVBAR_BACKGROUND:
      return {...state, showNavbarBackground: action.value}
    default:
      return state
  }
}


export default navigation
