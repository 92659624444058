import React, { Component } from 'react'
import './WhatWeDoService.css'
import ScrollMagic from "../../../../plugins/ScrollMagic"

class WhatWeDoService extends Component {
    constructor(props) {
        super(props)

        this.id = `what-we-do-service-${props.num}`
        this.triggerId = `what-we-do-service-trigger-${props.num}`
    }

    componentDidMount() {
        this.controller = new ScrollMagic.Controller()
        let discover = document.getElementById(this.id)

        new ScrollMagic.Scene({triggerElement: `#${this.triggerId}`})
            .on("enter", function() {
                discover.style.opacity = "1";
                discover.style.webkitTransform = "translate3d(0,0,0)";
                discover.style.transform = "translate3d(0,0,0)";
            })
            .on("leave", function () {
                discover.style.opacity = "0";
                discover.style.webkitTransform = "translate3d(0,15%,0)";
                discover.style.transform = "translate3d(0,15%,0)";
            })
            .addTo(this.controller);
    }

    render() {
        let { header, body, footer, image, color } = this.props
        return (
            <div id={this.id} className={"what-we-do-service"}>
                <div id={this.triggerId} className={'what-we-do-service-trigger'}/>
                <div className={"what-we-do-card"}>
                    <img className={"what-we-do-icon"} src={image}/>
                    <div className={"what-we-do-circle"}/>
                    <div className={"what-we-do-drop"}/>
                    <div className={"what-we-do-header-wrap"}>
                        <div className={"what-we-do-block"}>
                            <div className={"what-we-do-block-line"}/>
                        </div>
                        <span style={{color}} className={"what-we-do-header"}>
                                    {header}
                                </span>
                    </div>
                    <div className={"what-we-do-description"}>
                        <div className={"what-we-do-description-cell"}>
                            {body}
                        </div>
                    </div>
                    <div className={"what-we-do-footer"}>
                        <div>
                            {footer}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WhatWeDoService
