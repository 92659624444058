import React, { Component } from 'react'
import { iconOverDark as IconOverDark } from '../globals/logos/Logos'
import { gqlConnect } from 'react-connect-graphql-beta'
import { withRouter, Link } from 'react-router-dom'

const getSubdomain = (hostname) => {
  var regexParse = new RegExp('[a-z-0-9]{2,63}.[a-z.]{2,5}$')
  var urlParts = regexParse.exec(hostname)
  return hostname.replace(urlParts[0], '').slice(0, -1)
}

const getCdnUrl = () => {
  const subdomain = getSubdomain(window.location.hostname)
  return (
    'http://' +
    (subdomain.includes('test') !== false || subdomain === '' ? 'test.' : '') +
    'cdn.oos-software.com'
  )
}

class FooterContainer extends Component {
  render() {
    var ContactComponents
    const { data } = this.props.gql
    const { contactBlock, socialBlock, description, motto } = data[0]

    ContactComponents = contactBlock.map((contact, key) => {
      if (contact.contactType === 'email') {
        return (
          <div
            className={'footer-contact col-md-12 col-sm-10 col-xs-10'}
            key={key}>
            <span className={'contact-type'}>{contact.contactTypeDisplay}</span>
            <a
              className={'contact-href'}
              href="mailto:contact@oos-software.com?subject=Hello oos">
              {contact.contactValue}
            </a>
          </div>
        )
      } else {
        return (
          <div
            className={'footer-contact col-md-12 col-sm-10 col-xs-10'}
            key={key}>
            <span className={'contact-type'}>{contact.contactTypeDisplay}</span>
            <span className={'contact-normal'}>{contact.contactValue}</span>
          </div>
        )
      }
    })

    return (
      <div
        id={'footer-container'}
        className={'footer'}
        style={this.props.style}>
        <div className={'row'}>
          <div className={'hidden-lg hidden-md  col-sm-1 col-xs-1'} />
          <IconOverDark />
          <div className={'hidden-lg hidden-md  col-sm-1 col-xs-1'} />
        </div>
        <div className={'row about-us'}>
          <div className={'hidden-lg hidden-md  col-sm-1 col-xs-1'} />
          <div className={'col-md-12 col-sm-10 col-xs-10'}>
            <span>{description}</span>
            <span id={'about-us-summary'}>{motto}</span>
          </div>
          <div className={'hidden-lg hidden-md  col-sm-1 col-xs-1'} />
        </div>
        <div className={'row contact'}>{ContactComponents}</div>
        <div className={'row logos'}>
          <div className={'hidden-lg hidden-md  col-sm-1 col-xs-1'} />
          <div className={'col-md-12 col-sm-10 col-xs-10'}>
            {socialBlock.map((item, key) => {
              return (
                <a
                  key={key}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                  href={item.socialLink}>
                  <img
                    className={'logo'}
                    id={'facebook'}
                    src={item.socialIcon[0].url}
                  />
                </a>
              )
            })}
          </div>
          <div className={'hidden-lg hidden-md  col-sm-1 col-xs-1'} />
        </div>
        <div className={'row copyright'}>
          <div className={'hidden-lg hidden-md  col-sm-1 col-xs-1'} />
          <div className={'col-md-12 col-sm-10 col-xs-10'}>
            <span>© 2018 oos</span>
            <span className={'footer-divider'}>|</span>
            <Link to={'/privacy_policy'} className={'privacy-policy-href'}>
              {' '}
              Privacy Policy
            </Link>
          </div>
          <div className={'hidden-lg hidden-md  col-sm-1 col-xs-1'} />
        </div>
      </div>
    )
  }
}

export default gqlConnect('footer')(withRouter(FooterContainer))
