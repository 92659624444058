import $ from 'jquery'

export const SET_NAVBAR_BACKGROUND = 'SET_NAVBAR_BACKGROUND'
export const SET_SHOW_NAVBAR = 'SET_SHOW_NAVBAR'

export const setNavbarBackground = show => {
  return {
    type: SET_NAVBAR_BACKGROUND,
    value: show,
  }
}

export const setShowNavbar = show => dispatch => {
  let nav = $('#navbar')

  if(show) {
    nav.css("transform", "translateY(0)")
    nav.css("transition", "transform .5s ease-out, opacity 0s 0s")
    nav.css("opacity", 1)
  } else {
    nav.css("transform", "translateY(-100%)")
    nav.css("transition", "transform .5s ease-out, opacity 0s 0.5s")
    nav.css("opacity", 0)
  }
  dispatch({type: SET_SHOW_NAVBAR, value: show,})
}
