import React, { Component } from 'react'
import GridItem from './GridItem'
import { withSizes } from '@oos-studio/website-common-components'
import deepmerge from 'deepmerge'
import '../home/animate.css'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Controls, PlayState } from 'react-gsap'
import { v4 as uuidv4 } from 'uuid'

class GridSection extends Component {
  state = {
    id: uuidv4(),
  }
  render() {
    const {
      heading,
      items,
      getStyle,
      style,
      containerStyles,
      itemStyles,
    } = this.props
    const { id } = this.state

    const _styles = containerStyles
      ? deepmerge(styles, containerStyles)
      : styles

    return (
      <div style={getStyle(_styles.container)}>
        <Controller>
          <Scene
            triggerHook={'onEnter'}
            triggerElement={`#heading_${id}`}
            duration={1}>
            {(progress) => {
              return (
                <Tween
                  duration={0.5}
                  ease={'power2.out'}
                  from={{ opacity: 0, y: '100%' }}
                  to={{ opacity: 1, y: '0%' }}
                  playState={progress ? PlayState.play : PlayState.stop}>
                  <div id={`heading_${id}`} style={getStyle(_styles.heading)}>
                    {heading}
                  </div>
                </Tween>
              )
            }}
          </Scene>
        </Controller>
        {style === 'simple' && (
          <Controller>
            <Scene
              triggerHook={'onEnter'}
              triggerElement={`#underline_${id}`}
              duration={1}>
              {(progress) => {
                return (
                  <Tween
                    duration={0.5}
                    from={{ scaleX: 0 }}
                    to={{ scaleX: 1 }}
                    playState={progress ? PlayState.play : PlayState.stop}>
                    <div
                      id={`underline_${id}`}
                      style={getStyle(_styles.divider)}
                    />
                  </Tween>
                )
              }}
            </Scene>
          </Controller>
        )}
        <div style={getStyle(_styles.gridContainer)}>
          {items.map((item, index) => {
            return (
              <GridItem
                itemStyles={itemStyles}
                style={style}
                icon={item.icon}
                text={item.name}
                url={item.url}
                entry={item.entryLink}
                index={index}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    paddingLeft: 75,
    paddingRight: 75,
    paddingTop: 75,
    paddingBottom: 95,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    md: {
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
  heading: {
    fontSize: 54,
    color: '#6AE1C6',
    textTransform: 'uppercase',
    fontWeight: 300,
    fontFamily: '"Gotham A", "Gotham B"',
    sm: {
      fontSize: 45,
    },
    xs: {
      fontSize: 38,
    },
    xxs: {
      fontSize: 32,
    },
  },
  gridContainer: {
    width: '100%',
    maxWidth: 1200,
    marginTop: 100,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    md: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      marginTop: 50,
    },
    xxs: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      marginTop: 25,
    },
  },
  divider: {
    height: 2,
    width: 250,
    backgroundColor: '#C9C9C7',
    marginTop: 50,
    marginBottom: 25,
  },
}

export default withSizes(GridSection)
