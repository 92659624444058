import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navigation2 from '../navigation/Navigation2'
import Home from '../home/Home.js';
import AboutUs from '../aboutUs/AboutUs.js'
import PrivacyPolicy from '../privacyPolicy/PrivacyPolicy.js';
import NotFound from '../notFound/NotFound.js';
import {isMobile} from "mobile-device-detect"
import Footer from "../footer/Footer"
import BlogListPage from "../blog/BlogListPage"
import ArticlePage from '../article/ArticlePage'
import ModalVideo from "react-modal-video"
import SolutionsPage from '../solutions/SolutionsPage'
import ConsultingPage from '../consulting/ConsultingPage'
import ServicesPage from '../services/ServicesPage'

class App extends Component {
    portraitVH = 0
    landscapeVH = 0

    componentDidMount() {
        if (isMobile) {
            let that = this
            setTimeout(function() {
                let vh = window.innerHeight * 0.01;
                if (window.innerHeight >= window.innerWidth) {
                    that.portraitVH = vh
                } else {
                    that.landscapeVH = vh
                }
                document.documentElement.style.setProperty('--vh', `${vh}px`)
                window.addEventListener('resize', that.onResize)
            })
        }
    }

    onResize = () => {
        let vh = window.innerHeight * 0.01
        if (window.innerHeight >= window.innerWidth) {
            if (this.portraitVH > vh) {
                this.portraitVH = vh
            } else {
                if (this.portraitVH === 0) {
                    this.portraitVH = vh
                }
                vh = this.portraitVH
            }
        } else {
            if (this.landscapeVH > vh) {
                this.landscapeVH = vh
            } else {
                if (this.landscapeVH === 0) {
                    this.landscapeVH = vh
                }
                vh = this.landscapeVH
            }
        }
        document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    render () {
        return (
            <Router>
                <div className="App">
                    <Navigation2 />
                    <Footer/>
                    <div className={'footer-bottom-margin-element'}>
                        <Switch>
                            <Route exact path={'/'} component={Home}/>
                            <Route exact path={'/about'} component={AboutUs}/>
                            <Route exact path={'/privacy_policy'} component={PrivacyPolicy}/>
                            <Route exact path={'/articles'} component={BlogListPage}/>
                            <Route exact path={'/articles/*'} component={ArticlePage}/>
                            <Route exact path={'/solutions/*'} component={() => <SolutionsPage key={window.location.pathname} />}/>
                            <Route exact path={'/services/*'} component={() => <ServicesPage key={window.location.pathname} />}/>
                            <Route path={'*'} component={NotFound}/>
                        </Switch>
                    </div>
                </div>
            </Router>
        )
    }
}

export default App;
