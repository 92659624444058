import React, {Component} from "react";
import './WhatWeDo.css';
import $ from 'jquery';
import ScrollMagic from "../../../plugins/ScrollMagic";
import WhatWeDoService from './whatWeDoService/WhatWeDoService'
import SloganItem from "../computerAnimation/ComputerAnimation"

class WhatWeDo extends Component {
    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize)
    }

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
        this.onResize();

        this.controller = new ScrollMagic.Controller();

        let underline = document.getElementById('what-we-do-header-underline');
        let mainHeader = document.getElementById('what-we-do-header-text');

        new ScrollMagic.Scene({triggerElement: "#what-we-do-trigger"})
            .on("enter", function() {
                underline.style.width = `${mainHeader.offsetWidth * .65}px`;
                underline.style.transitionDelay = ".25s";
                underline.style.webkitTransitionDelay = ".25s";
            })
            .on("leave", function() {
                underline.style.width = "0";
                underline.style.transitionDelay = ".25s";
                underline.style.webkitTransitionDelay = ".25s";
            })
            .addTo(this.controller);

        new ScrollMagic.Scene({triggerElement: "#what-we-do-trigger"})
            .on("enter", function() {
                mainHeader.style.opacity = "1";
                mainHeader.style.webkitTransform = "translate3d(0,0,0)";
                mainHeader.style.transform = "translate3d(0,0,0)";
                mainHeader.style.transitionDelay = ".25s";
                mainHeader.style.webkitTransitionDelay = ".25s";
            })
            .on("leave", function() {
                mainHeader.style.opacity = "0";
                mainHeader.style.webkitTransform = "translate3d(0,25%,0)";
                mainHeader.style.transform = "translate3d(0,25%,0)";
                mainHeader.style.transitionDelay = "0s";
                mainHeader.style.webkitTransitionDelay = "0s";

            })
            .addTo(this.controller);

        let mainDescription = document.getElementById('what-we-do-description');

        new ScrollMagic.Scene({triggerElement: '#what-we-do-description-trigger'})
            .on("enter", function () {
                mainDescription.style.opacity = "1";
                mainDescription.style.webkitTransform = "translate3d(0,0,0)";
                mainDescription.style.transform = "translate3d(0,0,0)";
                mainDescription.style.transitionDelay = "0s";
                mainDescription.style.webkitTransitionDelay = "0s";
            })
            .on("leave", function() {
                mainDescription.style.opacity = "0";
                mainDescription.style.webkitTransform = "translate3d(0,25%,0)";
                mainDescription.style.transform = "translate3d(0,25%,0)";
                mainDescription.style.transitionDelay = "0s";
                mainDescription.style.webkitTransitionDelay = "0s";
            })
            .addTo(this.controller);

        /*let discover = document.getElementById('what-we-do-discover');

        new ScrollMagic.Scene({triggerElement: '#servicesSection'})
            .on("enter", function() {
                discover.style.opacity = "1";
                discover.style.webkitTransform = "translate3d(0,0,0)";
                discover.style.transform = "translate3d(0,0,0)";
                discover.style.transitionDelay = ".5s";
                discover.style.webkitTransitionDelay = ".5s";
            })
            .on("leave", function () {
                discover.style.opacity = "0";
                discover.style.webkitTransform = "translate3d(0,15%,0)";
                discover.style.transform = "translate3d(0,15%,0)";
                discover.style.transitionDelay = "0s";
                discover.style.webkitTransitionDelay = "0s";
            })
            .addTo(this.controller);

        let design = document.getElementById('what-we-do-design');

        /*new ScrollMagic.Scene({triggerElement: '#what-we-do-discover'})
            .on("enter", function() {
                design.style.opacity = "1";
                design.style.webkitTransform = "translate3d(0,0,0)";
                design.style.transform = "translate3d(0,0,0)";
            })
            .on("leave", function () {
                design.style.opacity = "0";
                design.style.webkitTransform = "translate3d(0,15%,0)";
                design.style.transform = "translate3d(0,15%,0)";
            })
            .addTo(this.controller);

        let develop = document.getElementById('what-we-do-develop');

        new ScrollMagic.Scene({triggerElement: '#what-we-do-design'})
            .on("enter", function() {
                develop.style.opacity = "1";
                develop.style.webkitTransform = "translate3d(0,0,0)";
                develop.style.transform = "translate3d(0,0,0)";
            })
            .on("leave", function () {
                develop.style.opacity = "0";
                develop.style.webkitTransform = "translate3d(0,15%,0)";
                develop.style.transform = "translate3d(0,15%,0)";
            })
            .addTo(this.controller);*/
    }

    onResize = () => {
        var cw = $('.what-we-do-diag').width();
        $('.what-we-do-diag').css({'height':cw+'px'});

        let mainHeader = document.getElementById('what-we-do-header-text');
        let underline = document.getElementById('what-we-do-header-underline');
        underline.style.width = `${mainHeader.offsetWidth * .65}px`;
    };

    render() {
        let { serviceBlocks } = this.props

        return (
            <div className={'what-we-do-background'} id={'servicesSection'}>
                <div id={'what-we-do-trigger'}/>
            <div id={"what-we-do"}>
                <div id={"what-we-do-header"}>
                    <div id={"what-we-do-header-wrap"}>
                        <span id={"what-we-do-header-text"}>{this.props.title}</span>
                        <div id={"what-we-do-header-underline"}/>
                    </div>
                </div>
                <div id={'what-we-do-description-trigger'}/>
                <div id={"what-we-do-description"}>
                    <span id={"what-we-do-description"}>{this.props.description}</span>
                </div>
                <div id={"what-we-do-services"}>
                    {serviceBlocks.map((item, key) => {
                        return (
                            <WhatWeDoService header={item.header}
                                body={item.body}
                                footer={item.footer}
                                image={item.image[0].url}
                                color={item.color}
                                key={key}
                                num={key}/>
                        )
                    })}
                </div>
            </div>
            </div>
        );
    }

}

export default WhatWeDo;
