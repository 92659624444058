import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import { setStyleClasses } from '../../utils/HTMLTags'
import Radium from 'radium'
import { withRouter, Link } from 'react-router-dom'
import CallToAction from './CallToAction'
import Video from './Video'

class ContentBlock extends Component {
  state = {
    text: '',
    highlightText: '',
  }

  componentDidMount() {
    const isDarkTheme = this.props.data.theme === 'dark'
    this.setState({
      text: setStyleClasses(this.props.data.text, false, isDarkTheme),
      highlightText: setStyleClasses(this.props.data.text, true, isDarkTheme),
    })
  }

  renderBlock = () => {
    const { data, md } = this.props
    const { text, highlightText } = this.state

    const isLeft = data.imagePosition === 'left'
    const isRight = data.imagePosition === 'right'
    const isTop = data.imagePosition === 'top'
    const isBottom = data.imagePosition === 'bottom'

    const isDarkTheme = data.theme === 'dark'

    const blockStyles = {
      container: {
        width: '100%',
        display: 'flex',
        flexDirection: md || isTop || isBottom ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
      imgWrapper: {
        maxWidth: md ? '100%' : `${data.imageWidthPercentage}%`,
        minWidth: md ? '100%' : `${data.imageWidthPercentage}%`,
        overflow: 'hidden',
        height: '100%',
        transform: 'scale(1)',
        borderRadius: 4,
        transition: 'transform 1s cubic-bezier(.19,1,.22,1)',
        ':hover': {
          transform: 'scale(1.0275)',
          filter: 'drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.05))',
        },
      },
      imageWithLink: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        cursor: 'pointer',
        transform: 'scale(1.125)',
        transition: 'transform 1s cubic-bezier(.19,1,.22,1)',
        ':hover': {
          transform: 'scale(1)',
        },
      },
      image: {
        width: md ? '100%' : `${data.imageWidthPercentage}%`,
      },
      text: {},
      textWrapper: {
        width: '100%',
        marginTop: isTop || (isLeft && md) ? 35 : 0,
        marginBottom: isBottom || (isRight && md) ? 35 : 0,
        marginLeft: isLeft && !md ? 35 : 0,
        marginRight: isRight && !md ? 35 : 0,
      },
    }

    return (
      <div>
        <div
          key={0}
          className={isDarkTheme ? 'content-block dark' : 'content-block'}
          style={blockStyles.container}>
          {(isBottom || isRight) && (
            <div style={blockStyles.textWrapper}>
              <div
                style={blockStyles.text}
                dangerouslySetInnerHTML={{ __html: text }}
              />
              {data.callToActions && data.callToActions.length > 0 && (
                <CallToAction data={data.callToActions} />
              )}
            </div>
          )}
          {data.image &&
            (data.imageEntryLink.length > 0 ? (
              <div key={1} style={blockStyles.imgWrapper}>
                {data.image[0]?.mimeType.includes('image/') && (
                  <Link
                    to={`/${data.imageEntryLink[0].uri}`}
                    style={{ textDecoration: 'none' }}>
                    <img
                      key={2}
                      src={data.image[0]?.url}
                      alt={'contentBlockImg'}
                      style={blockStyles.imageWithLink}
                    />
                  </Link>
                )}
                {data.image[0]?.mimeType.includes('video/') && (
                  <Video
                    autoplay={data.autoplay}
                    controls={data.controls}
                    loop={data.loop}
                    source={data.image[0]?.url}
                    type={data.image[0]?.mimeType}
                    styles={blockStyles.imageWithLink}
                  />
                )}
              </div>
            ) : (
              <>
                {data.image[0]?.mimeType.includes('image/') && (
                  <img
                    src={data.image[0]?.url}
                    alt={'contentBlockImg'}
                    style={blockStyles.image}
                  />
                )}
                {data.image[0]?.mimeType.includes('video/') && (
                  <Video
                    autoplay={data.autoplay}
                    controls={data.controls}
                    loop={data.loop}
                    source={data.image[0]?.url}
                    type={data.image[0]?.mimeType}
                    styles={blockStyles.image}
                  />
                )}
              </>
            ))}
          {(isTop || isLeft) && (
            <div style={blockStyles.textWrapper}>
              <div
                style={blockStyles.text}
                dangerouslySetInnerHTML={{ __html: text }}
              />
              {data.callToActions && data.callToActions.length > 0 && (
                <CallToAction data={data.callToActions} />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { data, getStyle } = this.props
    const { renderBlock } = this

    return (
      <div
        style={{
          ...getStyle(styles.container),
          backgroundColor: data.backgroundColor[0].colorValue,
        }}>
        <div style={styles.content}>{renderBlock()}</div>
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100%',
    paddingTop: 45,
    paddingBottom: 45,
    paddingLeft: 60,
    paddingRight: 60,
    md: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  content: {
    maxWidth: 1200,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

export default withRouter(withSizes(Radium(ContentBlock)))
