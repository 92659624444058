import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
} from 'reactstrap'
import $ from 'jquery'
import { landscapeLogoOverPrimary as LandscapeLogoOverPrimary } from '../globals/logos/Logos.js'
import '../../fonts/fonts.css'
import './Navigation2.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-regular-svg-icons'
import { connect } from 'react-redux'
import { gqlConnect } from 'react-connect-graphql-beta'
import { setShowNavbar } from './actions'

let animating = false

class Navigation2 extends Component {
  state = {
    isOpen: false,
    activeDropdown: -1,
    isMobile: false,
    solutions: [],
    services: [],
    touchscreen: false,
  }

  constructor(props) {
    super(props)

    this.lastScrollTop = 0
    this.showing = true
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isMobile, activeDropdown } = this.state
    const { animateMobileDropdown } = this

    if (prevState.isMobile && !isMobile) {
      this.setState({
        activeDropdown: -1,
      })
    }

    if (prevState.isMobile !== isMobile && activeDropdown !== -1) {
      animateMobileDropdown(0, activeDropdown, false)
    }
  }

  componentDidMount() {
    const { checkWindowSize, onScroll } = this
    const { data } = this.props.gql

    window.addEventListener('touchend', () => {
      this.setState({
        touchscreen: true,
      })
    })

    checkWindowSize()
    onScroll()
    window.addEventListener('resize', checkWindowSize)
    window.addEventListener('scroll', onScroll)

    const solutions = data.filter((entry) => {
      return entry.__typename === 'solutions_solutions_Entry'
    })

    const services = data.filter((entry) => {
      return entry.__typename === 'services_services_Entry'
    })

    this.setState({
      solutions: solutions,
      services: services,
    })
  }

  componentWillUnmount() {
    const { checkWindowSize, onScroll } = this
    window.removeEventListener('resize', checkWindowSize)
    window.removeEventListener('scroll', onScroll)
  }

  checkWindowSize = () => {
    if (window.innerWidth >= 992) {
      $('.navbar-collapse').css({ backgroundColor: 'rgba(0, 207, 181, 0)' })
      this.setState({
        isOpen: true,
        isMobile: false,
      })
    } else {
      //$('.navbar-collapse').css({ backgroundColor: 'rgba(0, 207, 181, 0.9)' })
      this.setState({
        isOpen: false,
        isMobile: true,
      })
    }
  }

  toggleMenu = (isOpen) => {
    const { activeDropdown } = this.state
    const { animateMobileDropdown } = this

    if (window.innerWidth < 992) {
      this.setState({
        isOpen: typeof isOpen === 'boolean' ? isOpen : !this.state.isOpen,
      })
    } else {
      this.setState({ isOpen: true })
    }

    if (activeDropdown !== -1) {
      animateMobileDropdown(0, activeDropdown, false)
    }
  }

  pathClick = () => {
    const { toggleMenu } = this
    toggleMenu(false)
    this.setState({
      activeDropdown: -1,
    })
  }

  scrollToElement = (element) => {
    let container = $([document.documentElement, document.body])

    if (element) {
      animating = true
      container.animate(
        {
          scrollTop:
            element.getBoundingClientRect().top +
            document.documentElement.scrollTop,
        },
        1000,
        function () {
          animating = false
        },
      )
    }
  }

  stopScrollToElement = (interval) => {
    clearInterval(interval)
  }

  contactClick = () => {
    const { scrollToElement, stopScrollToElement } = this
    let interval

    let contactForm = document.getElementById('contact-us-form')

    if (!contactForm) {
      this.props.history.push('/')
      setTimeout(() => {
        contactForm = document.getElementById('contact-us-form')
      }, 100)
      interval = setInterval(function () {
        if (contactForm != null) {
          if (contactForm.offsetTop > 0) {
            scrollToElement(contactForm)
            stopScrollToElement(interval)
          }
        }
      }, 100)
    } else {
      scrollToElement(contactForm)
    }
    this.toggleMenu(false)
  }

  onScroll = () => {
    const { setShowNavbar } = this.props

    if (animating) {
      this.lastScrollTop = 0
      return
    }
    if (window.pageYOffset > 50) {
      let st = window.pageYOffset || document.documentElement.scrollTop
      if (this.lastScrollTop > 0) {
        if (st < this.lastScrollTop) {
          setShowNavbar(true)
        } else {
          setShowNavbar(false)
        }
      }
      this.lastScrollTop = st <= 0 ? 0 : st
    } else {
      setShowNavbar(true)
    }
  }

  animateMobileDropdown = (_numItems, index, open = null) => {
    const { activeDropdown } = this.state

    const height = _numItems * 32 + 16
    const menu = document.getElementsByClassName('dropdown-menu')[index]

    if (activeDropdown !== -1 && activeDropdown !== index) {
      const menuToClose = document.getElementsByClassName('dropdown-menu')[
        activeDropdown
      ]
      menuToClose.style.height = '0px'
      menuToClose.style.paddingTop = '0px'
      menuToClose.style.paddingBottom = '0px'
    }

    if (menu.clientHeight === 0 || (open !== null && open)) {
      menu.style.height = `${height}px`
      menu.style.paddingTop = '8px'
      menu.style.paddingBottom = '8px'
      this.setState({
        activeDropdown: index,
      })
    } else {
      menu.style.height = '0px'
      menu.style.paddingTop = '0px'
      menu.style.paddingBottom = '0px'
      this.setState({
        activeDropdown: -1,
      })
    }
  }

  render() {
    const { toggleMenu, pathClick, contactClick, animateMobileDropdown } = this
    const {
      isOpen,
      activeDropdown,
      isMobile,
      solutions,
      services,
      touchscreen,
    } = this.state

    return (
      <Navbar id={'navbar'} expand={'lg'}>
        <div className={'navigation-background'} />
        <Link to={'/'}>
          <NavbarBrand onClick={() => pathClick()}>
            <LandscapeLogoOverPrimary />
          </NavbarBrand>
        </Link>
        <NavbarToggler className={'navigation-toggler'} onClick={toggleMenu}>
          <span className={'navbar-toggler-icon'}>
            <FontAwesomeIcon
              icon={faBars}
              style={{ color: 'white', fontSize: 24 }}
            />
          </span>
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav className={'ml-auto'} navbar>
            <UncontrolledDropdown
              isOpen={true}
              onClick={
                isMobile || touchscreen
                  ? () =>
                      animateMobileDropdown(
                        solutions?.length,
                        0,
                        activeDropdown !== 0,
                      )
                  : null
              }
              onMouseEnter={
                isMobile || touchscreen
                  ? null
                  : () => animateMobileDropdown(solutions?.length, 0, true)
              }
              onMouseLeave={
                isMobile || touchscreen
                  ? null
                  : () => animateMobileDropdown(solutions?.length, 0, false)
              }
              className={
                activeDropdown === 0
                  ? 'navigation-link show'
                  : 'navigation-link'
              }
              nav
              inNavbar>
              {isMobile ? (
                <a
                  aria-haspopup="true"
                  href="javascript:void(0)"
                  className="nav-link"
                  aria-expanded="true">
                  Solutions
                </a>
              ) : (
                <div>Solutions</div>
              )}
              <DropdownMenu
                style={{ paddingTop: 0, paddingBottom: 0 }}
                visible
                left>
                <div
                  style={{
                    position: 'absolute',
                    top: -10,
                    height: 10,
                    backgroundColor: 'rgba(0,0,0,0)',
                    left: 0,
                    right: 0,
                  }}
                />
                <div
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.60)',
                    backdropFilter: 'blur(35px)',
                    WebkitBackdropFilter: 'blur(35px)',
                    padding: '0.5rem 0',
                  }}>
                  {solutions.map((entry, index) => {
                    return (
                      <Link
                        key={index}
                        to={`/${entry.uri}`}
                        onClick={() => pathClick()}
                        style={{ textDecoration: 'none' }}>
                        <DropdownItem key={index}>{entry.title}</DropdownItem>
                      </Link>
                    )
                  })}
                </div>
              </DropdownMenu>
              {isMobile && (
                <i
                  className={activeDropdown === 0 ? 'arrow up' : 'arrow down'}
                />
              )}
            </UncontrolledDropdown>
            <UncontrolledDropdown
              isOpen={true}
              onClick={
                isMobile || touchscreen
                  ? () =>
                      animateMobileDropdown(
                        services?.length,
                        1,
                        activeDropdown !== 1,
                      )
                  : null
              }
              onMouseEnter={
                isMobile || touchscreen
                  ? null
                  : () => animateMobileDropdown(services?.length, 1, true)
              }
              onMouseLeave={
                isMobile || touchscreen
                  ? null
                  : () => animateMobileDropdown(services?.length, 1, false)
              }
              className={
                activeDropdown === 1
                  ? 'navigation-link show'
                  : 'navigation-link'
              }
              nav
              inNavbar>
              {isMobile ? (
                <a
                  aria-haspopup="true"
                  href="javascript:void(0)"
                  className="nav-link"
                  aria-expanded="true">
                  Services
                </a>
              ) : (
                <div>Services</div>
              )}
              <DropdownMenu
                style={{ paddingTop: 0, paddingBottom: 0 }}
                visible
                left>
                <div
                  style={{
                    position: 'absolute',
                    top: -10,
                    height: 10,
                    backgroundColor: 'rgba(0,0,0,0)',
                    left: 0,
                    right: 0,
                  }}
                />
                <div
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.60)',
                    backdropFilter: 'blur(35px)',
                    WebkitBackdropFilter: 'blur(35px)',
                    padding: '0.5rem 0',
                  }}>
                  {services.map((entry, index) => {
                    return (
                      <Link
                        key={index}
                        to={`/${entry.uri}`}
                        onClick={() => pathClick()}
                        style={{ textDecoration: 'none' }}>
                        <DropdownItem key={index}>{entry.title}</DropdownItem>
                      </Link>
                    )
                  })}
                </div>
              </DropdownMenu>
              {isMobile && (
                <i
                  className={activeDropdown === 1 ? 'arrow up' : 'arrow down'}
                />
              )}
            </UncontrolledDropdown>
            <Link
              to={'/about'}
              className={'navigation-link'}
              style={{ textDecoration: 'none' }}
              onClick={() => pathClick()}>
              <NavItem style={{ paddingLeft: 0 }}>About</NavItem>
            </Link>
            <Link
              to={'/articles'}
              className={'navigation-link'}
              style={{ textDecoration: 'none' }}
              onClick={() => pathClick()}>
              <NavItem style={{ paddingLeft: 0 }}>Articles</NavItem>
            </Link>
            <Link
              to={'/'}
              className={'navigation-link link-border'}
              onClick={contactClick}
              style={{ textDecoration: 'none', height: 'fit-content' }}>
              <NavItem style={{ paddingLeft: 0 }}>Let's Talk</NavItem>
            </Link>
          </Nav>
          <div className={'collapse-background'} />
        </Collapse>
      </Navbar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    showBackground: state.showNavbarBackground,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowNavbar: (show) => dispatch(setShowNavbar(show)),
  }
}

export default gqlConnect('navbarDropdowns')(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation2)),
)
