import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import { withRouter, Link } from 'react-router-dom'

class ListItem extends Component {
  state = {
    hovered: false,
  }
  onClick = () => {
    const { item } = this.props
    if (item.url) {
      window.open(item.url, '_blank')
    }
  }
  onMouseEnter = () => {
    this.setState({
      hovered: true,
    })
  }
  onMouseLeave = () => {
    this.setState({
      hovered: false,
    })
  }
  render() {
    const { item, getStyle } = this.props
    const { styles, onClick, onMouseEnter, onMouseLeave } = this
    let uri = ''
    if (item.entry.length > 0) {
      uri = item.entry[0].uri
    }
    return (
      <div style={getStyle(styles().container)}>
        <div style={getStyle(styles().subWrapper)}>
          <div style={getStyle(styles().imageWrapper)}>
            <img
              onClick={() => onClick()}
              onMouseEnter={() => onMouseEnter()}
              onMouseLeave={() => onMouseLeave()}
              src={item.image}
              style={getStyle(styles().image)}
              alt={item.name}
            />
          </div>
          <Link
            to={uri}
            onClick={() => onClick()}
            onMouseEnter={() => onMouseEnter()}
            onMouseLeave={() => onMouseLeave()}
            style={getStyle(styles().infoWrapper)}>
            <div style={getStyle(styles().name)}>
              <mark className={'highlight'}>{item.name}</mark>
            </div>
            <div style={getStyle(styles().body)}>{item.body}</div>
            <div style={styles().buttonWrapper}>
              <span>{item.buttonText}</span>
              <i style={styles().arrow} />
            </div>
          </Link>
        </div>
      </div>
    )
  }

  styles = () => {
    const { index } = this.props
    const { hovered } = this.state

    const isEven = index % 2 === 0

    return {
      container: {
        backgroundColor: isEven ? '#FFFFFF' : '#F7F7F7',
        width: '100%',
        paddingLeft: 75,
        paddingRight: 75,
        paddingTop: 50,
        paddingBottom: 50,
        display: 'flex',
        justifyContent: 'center',
        fontFamily: '"Gotham A", "Gotham B"',
        md: {
          paddingLeft: 35,
          paddingRight: 35,
        },
      },
      subWrapper: {
        width: '100%',
        maxWidth: 1200,
        display: 'flex',
        flexDirection: isEven ? 'row' : 'row-reverse',
        justifyContent: 'center',
        sm: {
          flexDirection: 'column',
          alignItems: 'center',
        },
      },
      imageWrapper: {
        cursor: 'pointer',
        maxWidth: 400,
        maxHeight: 400,
        minWidth: 400,
        minHeight: 400,
        borderRadius: '50%',
        overflow: 'hidden',
        transform: hovered
          ? 'scale(1.0275) translateZ(0)'
          : 'scale(1.0) translateZ(0)',
        transition: 'transform 1s cubic-bezier(0.19, 1, 0.22, 1)',
        flex: 1,
        md: {
          maxWidth: 300,
          maxHeight: 300,
          minWidth: 300,
          minHeight: 300,
        },
        xxs: {
          maxWidth: 250,
          maxHeight: 250,
          minWidth: 250,
          minHeight: 250,
        },
      },
      image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transform: hovered ? 'scale(1.0)' : 'scale(1.125)',
        transition: 'transform 1s cubic-bezier(0.19, 1, 0.22, 1)',
      },
      infoWrapper: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        textAlign: 'left',
        maxWidth: '50%',
        paddingLeft: isEven ? 75 : 0,
        paddingRight: isEven ? 0 : 75,
        marginTop: 0,
        height: 'fit-content',
        alignSelf: 'center',
        textDecoration: 'none',
        sm: {
          marginTop: 20,
          maxWidth: 'none',
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      name: {
        fontSize: 36,
        fontWeight: 300,
        color: '#007589',
        marginBottom: 20,
        sm: {
          fontSize: 32,
        },
      },
      body: {
        fontSize: 18,
        color: '#4D4E56',
        fontWeight: 400,
        xs: {
          fontSize: 16,
        },
      },
      buttonWrapper: {
        fontFamily: '"Gotham A", "Gotham B"',
        color: hovered ? '#86b3bc' : '#007589',
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        marginTop: 35,
        letterSpacing: 2,
      },
      arrow: {
        borderStyle: 'solid',
        borderColor: hovered ? '#86b3bc' : '#007589',
        borderWidth: '0 2px 2px 0',
        display: 'inline-block',
        padding: 5,
        marginLeft: 7,
        marginTop: 11,
        transform: hovered
          ? 'rotate(-45deg) translate3d(5px, 7px, 0)'
          : 'rotate(-45deg) translate3d(0, 2px, 0)',
        transition: 'transform 0.25s',
      },
    }
  }
}

export default withRouter(withSizes(ListItem))
